<template>
  <v-dialog v-model="show" max-width="25%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small class="ml-2">
        mdi-information-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center"> Kiosk Information </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-icon><v-icon>mdi-monitor</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ kioskObject.data().name }}
              </v-list-item-title>
              <v-list-item-subtitle>Kiosk Name</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon><v-icon>mdi-map-marker</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ kioskObject.data().location }}
              </v-list-item-title>
              <v-list-item-subtitle>Kiosk Location</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon
              ><v-icon>mdi-login-variant</v-icon></v-list-item-icon
            >
            <v-list-item-content>
              <v-list-item-title>
                {{ kioskObject.data().email }}
              </v-list-item-title>
              <v-list-item-subtitle>Kiosk Login Email</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn outlined @click="show = false" width="125"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "kioskInformation",
  props: {
    kioskObject: Object,
  },
  data() {
    return {
      show: null,
    };
  },
};
</script>